





































































import { VForm } from "@/models";
import { rentalsService } from "@/services";
import { coreStore, tenantsStore } from "@/store";
import { Tenant } from "@edmp/api";
import { defineComponent, Ref, ref } from "@vue/composition-api";

export default defineComponent({
  name: "tenantUpdate",
  props: {
    tenant: {
      type: Object as () => Partial<Tenant>,
      required: true,
    },

    // For send RentReceipt
    action: { type: String, required: false },
    transactionId: { type: String, required: false },
    realEstateAssetId: { type: String, required: false },
  },
  setup(props, context) {
    const validateInProgress: Ref<boolean> = ref(false);
    const lastName: Ref<string> = ref(props.tenant.lastName ?? "");
    const firstName: Ref<string> = ref(props.tenant.firstName ?? "");
    const email: Ref<string> = ref(props.tenant.email ?? "");

    function resetForm() {
      lastName.value = "";
      firstName.value = "";
      email.value = "";
      (context.refs.form as VForm).resetValidation();
    }

    async function validate() {
      validateInProgress.value = true;
      if ((context.refs.form as VForm).validate()) {
        try {
          const tenantToUpdate: Partial<Tenant> = {
            ...props.tenant,
            lastName: lastName.value,
            firstName: firstName.value,
            email: email.value,
          };
          // Update
          await tenantsStore.updateTenant(tenantToUpdate as Tenant);

          context.emit("validate", {
            lastName: lastName.value,
            firstName: firstName.value,
            email: email.value,
          });
          resetForm();
        } catch (error) {
          console.error(Error);
        }
      }
      if (props.action && props.action === "sendRentReceipt") {
        try {
          await rentalsService.rents.sendRentReceiptFromTransaction({
            tenantId: props.tenant.id ?? "",
            transactionId: props.transactionId ?? "",
          });
          coreStore.displayFeedback({
            message: "Votre quittance a bien été envoyée",
          });
        } catch (error) {
          console.error(error);
          coreStore.displayFeedback({
            message: "Une erreur s'est produite",
          });
        }
      }
      validateInProgress.value = false;
    }

    function closeDialog() {
      resetForm();
      context.emit("close");
    }

    return {
      lastName,
      firstName,
      email,
      validateInProgress,
      validate,
      resetForm,
      closeDialog,
    };
  },
});
