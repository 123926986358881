




































































































































































































































































































import { SubscriptionsModel } from "@edmp/api/";
import {
  computed,
  defineComponent,
  onBeforeMount,
  PropType,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { getPrice } from "./subscriptions.utils";
import { SubscriptionPlansItem } from "./Subscriptions.const";

export default defineComponent({
  name: "SubscriptionsPlanItem",
  props: {
    isLoading: { type: Boolean, required: true },
    isEditable: { type: Boolean, default: false },
    plan: {
      type: Object as PropType<SubscriptionPlansItem>,
      required: true,
    },
    planSelected: {
      type: Object as PropType<SubscriptionsModel.PlanParams>,
      required: true,
    },
  },
  setup(props, context) {
    /**
     * ProductData
     */
    const selectedPlan: Ref<SubscriptionsModel.PlanParams> = ref(
      props.planSelected
    );
    const availableDiscount: Ref<number> = ref(0);
    const price = computed(() => getPrice(props.plan, selectedPlan.value));

    /**
     * Action
     */
    const selectPlan = () => {
      selectedPlan.value.type = props.plan
        .type as SubscriptionsModel.PlanTypeParams;
      context.emit("selectPlan", selectedPlan.value);
    };

    /**
     * Mutations
     */
    // Recalculate coupon number if used or not
    watch(
      () => selectedPlan.value.couponUse,
      (val: boolean) => {
        val ? availableDiscount.value-- : availableDiscount.value++;
      }
    );
    watch(
      () => props.planSelected,
      () =>
        (selectedPlan.value = Object.assign(
          selectedPlan.value,
          props.planSelected
        )),
      { deep: true }
    );

    // Init
    const init = () => {
      if (props.plan.product && props.plan.product.coupon) {
        availableDiscount.value = props.plan.product.coupon.available;
        selectedPlan.value.couponUse =
          props.plan.product.coupon.available !== 0 ? true : false;
      }
    };

    watch(
      () => props.plan.product,
      () => init(),
      { deep: true }
    );

    onBeforeMount(() => {
      init();
    });

    /**
     * Return datas
     */
    return {
      price,
      availableDiscount,
      PlanTypeParams: SubscriptionsModel.PlanTypeParams,
      DurationTypeParams: SubscriptionsModel.DurationTypeParams,
      selectedPlan,
      selectPlan,
    };
  },
});
