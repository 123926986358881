import {
  AnomalyCode,
  AnomalyError,
  AnomalyErrorResult,
  CheckNewAnomaliesParams,
  CheckNewAnomaliesType,
  JournalComposedEntry,
  JournalComposedEntryWithTransaction,
  JournalEntryLine,
  TypeReference,
} from "..";
import { anomaliesHelpers } from "./anomaliesHelper.lib";

export type CheckTypeReferenceAnomaliesParams = Omit<
  CheckNewAnomaliesParams<CheckNewAnomaliesType.transaction>,
  "checkNewAnomaliesType" | "params"
> & {
  operation: JournalComposedEntryWithTransaction;
  line: JournalEntryLine;
  categories: CheckNewAnomaliesParams<CheckNewAnomaliesType.transaction>["params"]["categories"];
};

export const checkTypeReferenceAnomalies = ({
  operation,
  line,
  categories,
  data,
  options,
}: CheckTypeReferenceAnomaliesParams): AnomalyErrorResult[] => {
  const {
    getBankAccount,
    getRealEstateAsset,
    getRentalUnit,
    getRentalAgreement,
    getPartner,
    getRealEstateLoan,
    getSupportingDocument,
    hasOptionAnomalyParamsKey,
    getOptionAnomalyParamsKey,
  } = anomaliesHelpers({ data, options });
  const anomaliesErrorsLine: AnomalyErrorResult[] = [];

  /* Checking if the reference type is a bank account, real estate asset, rental unit, rental
  agreement, partner, or real estate loan. If it is, it checks if the referredId exists in
  the database. If it doesn't, it adds the anomaly error to the anomaliesErrorsLine array. */
  line.refs?.forEach((ref) => {
    switch (ref.type) {
      case TypeReference.bankAccount:
        if (!getBankAccount(ref.referredId)) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeBankAccountRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: ref.type,
            toReferenceId: ref.referredId,
          });
        }
        break;

      case TypeReference.realEstateAsset:
        if (!getRealEstateAsset(ref.referredId)) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRealEstateAssetRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: ref.type,
            toReferenceId: ref.referredId,
          });
        }
        break;

      case TypeReference.rentalUnit:
        const rentalUnitRef = getRentalUnit(ref.referredId);
        if (hasOptionAnomalyParamsKey(AnomalyCode.referenceType, TypeReference.rentalUnit)) {
          if (!!getOptionAnomalyParamsKey(AnomalyCode.referenceType, TypeReference.rentalUnit)?.deleted) {
            anomaliesErrorsLine.push({
              anomalyError: AnomalyError.referenceTypeRentalUnitRequired,
              fromReferenceType: "transaction",
              fromReferenceId: operation.transactionId,
              toReferenceType: ref.type,
              toReferenceId: ref.referredId,
            });
          }
        } else {
          if (!rentalUnitRef) {
            anomaliesErrorsLine.push({
              anomalyError: AnomalyError.referenceTypeRentalUnitRequired,
              fromReferenceType: "transaction",
              fromReferenceId: operation.transactionId,
              toReferenceType: ref.type,
              toReferenceId: ref.referredId,
            });
          }
        }
        if (rentalUnitRef) {
          const realEstateAssetRef = line.refs?.find((ref) => ref.type === TypeReference.realEstateAsset);
          if (realEstateAssetRef && realEstateAssetRef.referredId !== rentalUnitRef.realEstateAssetId) {
            console.log({ rentalUnitRef, realEstateAssetRef });
            anomaliesErrorsLine.push({
              anomalyError: AnomalyError.referenceTypeRentalUnitNotMatchRealEstateAsset,
              fromReferenceType: realEstateAssetRef.type,
              fromReferenceId: realEstateAssetRef.referredId,
              toReferenceType: ref.type,
              toReferenceId: ref.referredId,
            });
          }
        }
        break;

      case TypeReference.rentalAgreement:
        const rentalAgreementRef = getRentalAgreement(ref.referredId);
        if (!rentalAgreementRef) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRentalAgreementRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: ref.type,
            toReferenceId: ref.referredId,
          });
        }
        if (rentalAgreementRef) {
          const rentalUnitRef = line.refs?.find((ref) => ref.type === TypeReference.rentalUnit);
          if (
            rentalUnitRef &&
            rentalUnitRef.referredId !== rentalAgreementRef.product.realEstateAsset?.rentalUnit?.id
          ) {
            anomaliesErrorsLine.push({
              anomalyError: AnomalyError.referenceTypeRentalAgreementNotMatchRentalUnit,
              fromReferenceType: rentalUnitRef.type,
              fromReferenceId: rentalUnitRef.referredId,
              toReferenceType: ref.type,
              toReferenceId: ref.referredId,
            });
          }
        }
        break;

      case TypeReference.partner:
        if (!getPartner(ref.referredId)) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypePartnerRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: ref.type,
            toReferenceId: ref.referredId,
          });
        }
        break;

      case TypeReference.realEstateLoan:
        if (!getRealEstateLoan(ref.referredId)) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRealEstateLoanRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: ref.type,
            toReferenceId: ref.referredId,
          });
        }
        break;

      case TypeReference.supportingDocument:
        if (!getSupportingDocument(ref.referredId)) {
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeSupportingDocumentRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: ref.type,
            toReferenceId: ref.referredId,
          });
        }
        break;
    }
  });

  /* Add anomaly if the line has no the required references. */
  const categoryInfos = categories.find((category) => category.number === line.account);
  if (!categoryInfos) {
    throw new Error(`no categoryInfos found: ${JSON.stringify({ lineAccount: line.account })}`);
  }

  if (categoryInfos.required) {
    const referencesRequireNotIn = categoryInfos.required?.filter(
      (requiredField) => !line.refs?.map((ref) => ref.type).includes(requiredField)
    );
    referencesRequireNotIn.forEach((referenceRequireNotIn) => {
      switch (referenceRequireNotIn) {
        case TypeReference.bankAccount:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeBankAccountRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;

        case TypeReference.realEstateAsset:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRealEstateAssetRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;

        case TypeReference.rentalUnit:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRentalUnitRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;

        case TypeReference.rentalAgreement:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRentalAgreementRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;

        case TypeReference.partner:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypePartnerRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;

        case TypeReference.realEstateLoan:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeRealEstateLoanRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;

        case TypeReference.supportingDocument:
          anomaliesErrorsLine.push({
            anomalyError: AnomalyError.referenceTypeSupportingDocumentRequired,
            fromReferenceType: "transaction",
            fromReferenceId: operation.transactionId,
            toReferenceType: referenceRequireNotIn,
          });
          break;
      }
    });
  }

  return anomaliesErrorsLine;
};
