import Decimal from "decimal.js-light";
import {
  AmortisationLine,
  CategorizationEntry,
  getMonthlyPayment,
  matchAmount,
  matchDate,
  RealEstateLoan,
  RentalUnit,
} from "..";

export const categorizationRules = {
  /* A function that takes an array of CategorizationEntry and returns a Decimal. */
  getTotalAmountLines: (lines: CategorizationEntry[]): Decimal =>
    lines.reduce((acc, { amount }) => new Decimal(Number(amount)).plus(acc), new Decimal(0)),

  /* Checking if the total amount of the lines is equal to the transaction amount. */
  isEqualAmount: (amount: number, lines: CategorizationEntry[]): boolean =>
    categorizationRules.getTotalAmountLines(lines)?.equals(amount),

  /* Getting the missing amount of the transaction. */
  getMissingAmount: (amount: number, lines: CategorizationEntry[]): number => {
    const totalAmountLines = categorizationRules.getTotalAmountLines(lines);
    return new Decimal(amount).sub(totalAmountLines).toNumber();
  },

  /* Checking if the amount is positive. */
  isPositiveAmount: (amount: number): boolean => new Decimal(amount).isPositive(),

  taxTvaRules: {
    /* Checking if the rental unit is enabled. */
    isEnableForRentalUnit: (
      rentalUnitId: string,
      getRentalUnit: (rentalUnitId: string) => RentalUnit | undefined
    ): boolean => {
      const rentalUnit = getRentalUnit(rentalUnitId);
      return (rentalUnit && rentalUnit.taxTvaEnable) || false;
    },

    /* A function that takes an amount and returns a boolean. */
    tvaCollectedIsPositive: (amount: number): boolean => {
      return categorizationRules.isPositiveAmount(amount) || new Decimal(amount).isZero();
    },
    tvaCollectedIsIncorrect: (amountRent: number, amountTVA: number, charges?: CategorizationEntry): boolean => {
      if (charges) return amountRent + charges.amount !== amountTVA * 4;
      else return amountRent !== amountTVA * 4;
    },
  },
  loanCapitalBorrowedRules: {
    isEqualLoanRepaymentDeadlineAmount: (realEstateLoan: RealEstateLoan, transactionAmount: number): boolean => {
      return matchAmount(transactionAmount, getMonthlyPayment(realEstateLoan).neg().toNumber(), 0.1);
    },
    isEqualLoanRepaymentDeadlineDate: (
      amortisationLines: AmortisationLine[],
      transactionDate: string
    ): AmortisationLine | undefined =>
      amortisationLines?.find((line) => {
        return matchDate(line.paymentAt, transactionDate, 14);
      }),
    isEnableInsuranceForRealEstateLoan: (
      realEstateLoanId: string,
      getRealEstateLoan: (realEstateLoanId: string) => RealEstateLoan | undefined
    ): boolean => {
      const realEstateLoan = getRealEstateLoan(realEstateLoanId);
      return (realEstateLoan && realEstateLoan.insuranceIncludedInLoan) || false;
    },
  },
};
