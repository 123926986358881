





















































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
} from "@vue/composition-api";

import { dispatchPartnersAreaEvent, PartnersAreaEventCode } from "@/events";

import PageHeader from "../../atom/PageHeader.vue";
import { HelpingMessage } from "@/components/atom/helping";
import { BadgeNew } from "@/components/atom/badge";
import { productsStore, subscriptionsStore, usersStore } from "@/store";
import { getMoment } from "@edmp/api";

enum PartnersAreaCode {
  INVEST_IN_OLD_RENOVATED = "investInOldRenovated",
  CONSULT_EXPERT = "consultExpert",
  BOOST_ENERGY_SORE = "boostEnergyScore",
  BENEFIT_PERSONALIZED_PROFESSIONAL_ADVICE = "BenefitPersonalizedProfessionalAdvice",
  LAUNCH_REAL_ESTATE_PROJECT = "LaunchRealEstateProject",
}
type Partner = {
  title: string;
  resume: string;
  image: string;
  image_hover: string;
  categories: [string];
  link: string;
  btn: string;
  code: PartnersAreaCode;
};

export default defineComponent({
  name: "partnersArea",
  components: {
    PageHeader,
    HelpingMessage,
    BadgeNew,
  },
  props: {},

  setup() {
    const categories = {
      // Categories of tags
      Investissement: "Investissement",
      Conseils: "Conseils",
      Renovation: "Rénovation énergétique",
    };

    const selectFilter = [
      { name: "Tout afficher", value: 0 },
      { name: categories.Investissement, value: 1 },
      { name: categories.Renovation, value: 2 },
      { name: categories.Conseils, value: 3 },
    ];

    const arrayPartners: Partner[] = [
      {
        title: "Investissez dans l'ancien rénové",
        resume:
          "IZIMMO IMPACT vous guide dans un investissement clé en main pour la rénovation durable de l'immobilier ancien. Découvrez des projets sélectionnés pour leur potentiel de transformation durable, alliant rentabilité et respect de l'environnement.",
        image: "izimmo-impact-sombre.svg",
        image_hover: "izimmo-impact-hover.svg",
        categories: [categories.Investissement],
        link: "https://www.lareferencepierre.fr/com/izimmo_impact/bochure_lrp.pdf",
        btn: "Consulter l'offre",
        code: PartnersAreaCode.INVEST_IN_OLD_RENOVATED,
      },
      {
        title: "Consultez un expert",
        resume:
          "Découvrez des opportunités d'investissement immobilier adaptées à vos objectifs patrimoniaux. Bénéficiez d'une consultation sur mesure avec Corentin Le Houérou, expert en investissement et défiscalisation chez Le Comptoir Immobilier.",
        image: "lci-sombre.svg",
        image_hover: "lci-hover.svg",
        categories: [categories.Investissement],
        link: "https://calendly.com/colh-lecomptoirimmobilier/reunion-60-minutes?back=1&month=2024-02&utm_source=solution&utm_medium=Offre_Partenaire",
        btn: "Prendre rendez-vous",
        code: PartnersAreaCode.CONSULT_EXPERT,
      },
      {
        title: "Boostez votre note énergétique",
        resume:
          "Améliorez votre efficacité énergétique avec les audits détaillés d'Ithaque. Réalisez un bilan énergétique complet et bénéficiez de conseils sur mesure pour une rénovation éco-responsable.",
        image: "ithaque-sombre.svg",
        image_hover: "ithaque-hover.svg",
        categories: [categories.Renovation],
        link: "https://www.ithaque-renovation.fr/ownily?utm_source=partner&utm_medium=banner&utm_campaign=ownily&utm_id=Ownily",
        btn: "Consulter l'offre",
        code: PartnersAreaCode.BOOST_ENERGY_SORE,
      },
      {
        title: "Bénéficiez d'un conseil professionnel personnalisé",
        resume:
          "Une question comptable, fiscale ou patrimoniale ? Votre abonnement Ownily vous donne accès à une consultation auprès de notre Expert-Comptable partenaire à un tarif d'honoraire négocié pour vous !",
        image: "boubaker-sombre.svg",
        image_hover: "boubaker-hover.svg",
        categories: [categories.Conseils],
        link: "https://calendly.com/b-hedia/consultation-telephonique-expert-comptable?utm_source=solution&utm_medium=Offre_Partenaire",
        btn: "Prendre rendez-vous",
        code: PartnersAreaCode.BENEFIT_PERSONALIZED_PROFESSIONAL_ADVICE,
      },
      {
        title: "Lancez votre projet immobilier",
        resume:
          "LiberKeys réinvente l'achat et la vente grâce à sa plateforme digitale. Estimations rigoureuses par des experts du secteur, processus de vente simplifié, et suivi en temps réel, tout est conçu pour sécuriser et accélérer votre parcours immobilier. LiberKeys orchestre votre vente de l’estimation à la conclusion.",
        image: "liberkeys-sombre.svg",
        image_hover: "liberkeys-hover.svg",
        categories: [categories.Investissement],
        link: "https://www.liberkeys.com?utm_source=solution&utm_medium=Offre_Partenaire",
        btn: "Consulter l'offre",
        code: PartnersAreaCode.LAUNCH_REAL_ESTATE_PROJECT,
      },
    ];

    const filterSelected: Ref<{ name: string; value: Number } | undefined> =
      ref({ name: "Filtres", value: 0 });

    const changeFilterSelected = (item: { name: string; value: Number }) => {
      if (filterSelected.value !== item) filterSelected.value = item;
    };

    const applyClass = (category) => {
      switch (category) {
        case "Investissement":
          return "investissement";
        case "Conseils":
          return "conseils";
        case "Rénovation énergétique":
          return "renovation-energetique";
      }
    };

    let dataFilter = computed(() => {
      if (filterSelected.value?.value === 0) {
        // Tout afficher
        dataFilter = arrayPartners;
        return dataFilter;
      } else {
        dataFilter = arrayPartners.filter(
          (element) => element.categories[0] === filterSelected.value?.name
        );
        return dataFilter;
      }
    });

    const click = (code: Partner["code"]) => {
      let eventCode: PartnersAreaEventCode;
      switch (code) {
        case PartnersAreaCode.INVEST_IN_OLD_RENOVATED:
          eventCode = PartnersAreaEventCode.CLICK_INVEST_IN_OLD_RENOVATED;
          break;

        case PartnersAreaCode.CONSULT_EXPERT:
          eventCode = PartnersAreaEventCode.CLICK_CONSULT_EXPERT;
          break;

        case PartnersAreaCode.BOOST_ENERGY_SORE:
          eventCode = PartnersAreaEventCode.CLICK_BOOST_ENERGY_SORE;
          break;

        case PartnersAreaCode.BENEFIT_PERSONALIZED_PROFESSIONAL_ADVICE:
          eventCode =
            PartnersAreaEventCode.CLICK_BENEFIT_PERSONALIZED_PROFESSIONAL_ADVICE;
          break;

        case PartnersAreaCode.LAUNCH_REAL_ESTATE_PROJECT:
          eventCode = PartnersAreaEventCode.CLICK_LAUNCH_REAL_ESTATE_PROJECT;
          break;

        default:
          return;
      }

      dispatchPartnersAreaEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        subscriptionPlan:
          subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
        date: getMoment().toISOString(),
        code: eventCode,
      });
    };

    onMounted(() => {
      dispatchPartnersAreaEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        subscriptionPlan:
          subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
        date: getMoment().toISOString(),
        code: PartnersAreaEventCode.ENTER,
      });
    });
    onUnmounted(() => {
      dispatchPartnersAreaEvent({
        userId: usersStore.loggedInUser.id,
        productId: productsStore.currentId,
        subscriptionPlan:
          subscriptionsStore.getCurrentSubscription?.plan.type || "unknown",
        date: getMoment().toISOString(),
        code: PartnersAreaEventCode.QUIT,
      });
    });

    return {
      arrayPartners,
      applyClass,
      categories,
      changeFilterSelected,
      filterSelected,
      selectFilter,
      dataFilter,
      click,
    };
  },
});
