import {
  AnomalyCode,
  AnomalyError,
  AnomalyErrorResult,
  CheckNewAnomaliesParams,
  CheckNewAnomaliesType,
  TypeReference,
} from "..";
import { anomaliesHelpers } from "./anomaliesHelper.lib";

export type CheckObjectIdLinkAnomaliesParams = Omit<
  CheckNewAnomaliesParams<CheckNewAnomaliesType.functional>,
  "checkNewAnomaliesType" | "params"
>;

export const checkObjectIdLinkAnomalies = ({
  data,
  options,
}: CheckObjectIdLinkAnomaliesParams): AnomalyErrorResult[] => {
  const {
    getRentalUnit,
    hasOptionAnomalyParamsKey,
    getOptionAnomalyParamsKey,
    hasNoOptionOrOptionAnomalyCode,
    getOptionAnomalyReference,
  } = anomaliesHelpers({ data, options });
  const anomaliesErrors: AnomalyErrorResult[] = [];

  if (hasNoOptionOrOptionAnomalyCode(AnomalyCode.objectIdLink)) {
    for (const rentalAgreement of data.rentalAgreements) {
      if (hasOptionAnomalyParamsKey(AnomalyCode.objectIdLink, "rentalUnitDeleted")) {
        if (getOptionAnomalyParamsKey(AnomalyCode.objectIdLink, "rentalUnitDeleted")) {
          const referenceId = getOptionAnomalyReference(AnomalyCode.objectIdLink);
          if (referenceId && rentalAgreement.product.realEstateAsset?.rentalUnit?.id === referenceId) {
            anomaliesErrors.push({
              anomalyError: AnomalyError.objectIdLinkRentalUnitRequired,
              fromReferenceType: TypeReference.rentalAgreement,
              fromReferenceId: rentalAgreement.id,
              toReferenceType: TypeReference.rentalUnit,
              toReferenceId: referenceId,
            });
          }
        }
      } else {
        if (
          !rentalAgreement.product.realEstateAsset?.rentalUnit?.id ||
          !getRentalUnit(rentalAgreement.product.realEstateAsset?.rentalUnit?.id)
        ) {
          anomaliesErrors.push({
            anomalyError: AnomalyError.objectIdLinkRentalUnitRequired,
            fromReferenceType: TypeReference.rentalAgreement,
            fromReferenceId: rentalAgreement.id,
            toReferenceType: TypeReference.rentalUnit,
            toReferenceId: rentalAgreement.product.realEstateAsset?.rentalUnit?.id,
          });
        }
      }
    }
  }

  return anomaliesErrors;
};
