import { downloadFile } from "@/utils";
import {
  DocumentFileType,
  InseeData,
  mimeTypes,
  RentalAgreementsService,
  RentalBuildingsService,
  RentalUnitsService,
  RentIndexationTypeParamEnum,
  RentsService,
  TenantsService,
} from "@edmp/api";
import axios from "axios";

const api = "/api/v1/rentals";

class RentalsService {
  agreements = {
    async create(rentalAgreementCreate: RentalAgreementsService.CreateIn) {
      return axios
        .post<RentalAgreementsService.CreateOut>(
          `${api}/agreements`,
          rentalAgreementCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RentalAgreementsService.ListIn) {
      return axios
        .get<RentalAgreementsService.ListOut>(`${api}/agreements`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async getRentalAgreement(params: RentalAgreementsService.GetIn) {
      return axios
        .get<RentalAgreementsService.GetOut>(`${api}/${params.id}`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async update(rentalAgreementUpdate: RentalAgreementsService.UpdateIn) {
      return axios
        .put<RentalAgreementsService.UpdateOut>(
          `${api}/agreements/${rentalAgreementUpdate.id}`,
          rentalAgreementUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async remove(rentalAgreementDelete: RentalAgreementsService.DeleteIn) {
      return axios
        .delete<void>(`${api}/agreements/${rentalAgreementDelete.id}`)
        .then((res) => {
          return res.data;
        });
    },

    async downloadReceipt(params: RentalAgreementsService.ReceiptIn) {
      return axios
        .post<RentalAgreementsService.ReceiptOut>(
          `${api}/agreements/${params.id}/receipt`,
          params,
          {
            responseType: "blob", // Important : Format Blob !
            headers: {
              Accept: "application/pdf",
            },
          }
        )
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "")
            .split("filename=")[1]
            .trim()
            .replace(/"/g, "");
          for (const buffer of res.data)
            downloadFile(buffer, fileName, DocumentFileType.PDF);
        });
    },

    async downloadDocumentModel(
      params: RentalAgreementsService.DocumentModelIn
    ) {
      const fileType: DocumentFileType = params.fileType;

      return axios
        .post<RentalAgreementsService.DocumentModelOut>(
          `${api}/agreements/document-model`,
          params,
          {
            responseType: "blob", // Important : Format Blob !
            headers: {
              Accept: mimeTypes[fileType],
            },
          }
        )
        .then((res) => {
          const fileName = (res.headers["content-disposition"] || "")
            .split("filename=")[1]
            .trim()
            .replace(/"/g, "");
          downloadFile(res.data, fileName, fileType);
        });
    },

    async createRentIndexation(
      params: RentalAgreementsService.CreateRentIndexationIn
    ) {
      return axios
        .post<RentalAgreementsService.CreateRentIndexationOut>(
          `${api}/agreements/indexation`,
          params
        )
        .then((res) => {
          return res.data;
        });
    },

    async listIndexations(
      params: RentalAgreementsService.ListRentIndexationsIn
    ) {
      return axios
        .get<RentalAgreementsService.ListRentIndexationsOut>(
          `${api}/agreements/${params.rentalAgreementId}/indexation`
        )
        .then((res) => {
          return res.data;
        });
    },

    async getListIndexations(irlType: RentIndexationTypeParamEnum) {
      return axios
        .get<InseeData[]>(`${api}/agreements/indexation/${irlType}`, {
          headers: {
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          return res.data;
        });
    },
    async sendIndexation(params: RentalAgreementsService.SendIndexationIn) {
      return axios
        .post<RentalAgreementsService.SendIndexationOut>(
          `${api}/agreements/${params.id}/indexation/send-indexation`
        )
        .then((res) => {
          return res.data;
        });
    },
    async deleteIndexation(id: string) {
      return axios
        .delete<boolean>(`${api}/agreements/${id}/indexation`, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          return res.data;
        });
    },

    async deleteNoticeRentIndexation(id: string) {
      return axios
        .patch<RentalAgreementsService.DeleteNoticeRentIndexationOut>(
          `${api}/agreements/${id}/indexation/delete-notice-rent-indexation`
        )
        .then((res) => {
          return res.data;
        });
    },
    // async getPreviewMail(params: RentalAgreementsService.GetPreviewMailIn) {
    //   return axios
    //     .get<RentalAgreementsService.GetPreviewMailOut>(
    //       `${api}/agreements/${params.id}/getPreviewMail`,
    //       { params }
    //     )
    //     .then((res) => {
    //       return res.data;
    //     });
    // },

    async createRegularization(
      params: RentalAgreementsService.CreateRegularizationIn
    ) {
      return axios
        .post<RentalAgreementsService.CreateRegularizationOut>(
          `${api}/agreements/${params.rentalAgreementId}/regularization`,
          params,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          return res.data;
        });
    },
    async sendRegularizationMail(
      params: RentalAgreementsService.SendRegularizationIn
    ) {
      return axios
        .post<RentalAgreementsService.SendRegularizationOut>(
          `${api}/agreements/${params.id}/regularization/send-regularization`,
          params
        )
        .then((res) => {
          return res.data;
        });
    },

    async listRegularizations(
      params: RentalAgreementsService.ListRegularizationsIn
    ) {
      return axios
        .get<RentalAgreementsService.ListRegularizationsOut>(
          `${api}/agreements/${params.rentalAgreementId}/regularizations`
        )
        .then((res) => {
          return res.data;
        });
    },

    async deleteRegularization(id: string) {
      return axios
        .delete<boolean>(`${api}/agreements/${id}/regularization`, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((res) => {
          return res.data;
        });
    },
    async deleleNoticeRegularization(id: string) {
      return axios
        .patch<RentalAgreementsService.DeleteNoticeRegularizationOut>(
          `${api}/agreements/${id}/regularization/delete-notice-regularization`
        )
        .then((res) => {
          return res.data;
        });
    },
  };

  tenants = {
    async create(tenantCreate: TenantsService.CreateIn) {
      return axios
        .post<TenantsService.CreateOut>(`${api}/tenants`, tenantCreate)
        .then((res) => {
          return res.data;
        });
    },

    async list(params: TenantsService.ListIn) {
      return axios
        .get<TenantsService.ListOut>(`${api}/tenants`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async getTenant(params: TenantsService.GetIn) {
      return axios
        .get<TenantsService.GetOut>(`${api}/${params.id}`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async update(tenantUpdate: TenantsService.UpdateIn) {
      return axios
        .put<TenantsService.UpdateOut>(
          `${api}/tenants/${tenantUpdate.id}`,
          tenantUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async remove(tenantDelete: TenantsService.DeleteIn) {
      return axios
        .delete<void>(`${api}/tenants/${tenantDelete.id}`)
        .then((res) => {
          return res.data;
        });
    },
  };

  buildings = {
    async create(rentalBuildingCreate: RentalBuildingsService.CreateIn) {
      return axios
        .post<RentalBuildingsService.CreateOut>(
          `${api}/buildings`,
          rentalBuildingCreate
        )
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RentalBuildingsService.ListIn) {
      return axios
        .get<RentalBuildingsService.ListOut>(`${api}/buildings`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: RentalBuildingsService.GetIn) {
      return axios
        .get<RentalBuildingsService.ListOut>(`${api}/buildings/${params.id}`, {
          params,
        })
        .then((res) => {
          return res.data;
        });
    },

    async update(rentalBuildingUpdate: RentalBuildingsService.UpdateIn) {
      return axios
        .put<RentalBuildingsService.UpdateOut>(
          `${api}/buildings/${rentalBuildingUpdate.id}`,
          rentalBuildingUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    async remove(params: RentalBuildingsService.DeleteIn) {
      return axios
        .delete<RentalBuildingsService.DeleteOut>(
          `${api}/buildings/${params.id}`,
          { params }
        )
        .then((res) => {
          return res.data;
        });
    },
  };

  units = {
    async create(rentalUnitCreate: RentalUnitsService.CreateIn) {
      return axios
        .post<RentalUnitsService.CreateOut>(`${api}/units`, rentalUnitCreate)
        .then((res) => {
          return res.data;
        });
    },

    async list(params: RentalUnitsService.ListIn) {
      return axios
        .get<RentalUnitsService.ListOut>(`${api}/units`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async get(params: RentalUnitsService.GetIn) {
      return axios
        .get<RentalUnitsService.GetOut>(`${api}/units/${params.id}`, { params })
        .then((res) => {
          return res.data;
        });
    },

    async update(rentalUnitUpdate: RentalUnitsService.UpdateIn) {
      return axios
        .put<RentalUnitsService.UpdateOut>(
          `${api}/units/${rentalUnitUpdate.id}`,
          rentalUnitUpdate
        )
        .then((res) => {
          return res.data;
        });
    },

    // async move(params: RentalUnitsService.MoveIn) {
    //   return axios
    //     .post<RentalUnitsService.MoveOut>(`${api}/units/${params.id}/move`, {
    //       params,
    //     })
    //     .then((res) => {
    //       return res.data;
    //     });
    // },
  };
  rents = {
    async sendRentReceiptFromTransaction(
      params: RentsService.SendRentReceiptFromTransactionIn
    ) {
      return axios
        .post<RentsService.SendRentReceiptFromTransactionOut>(
          `${api}/rents/sendRentReceiptFromTransaction`,
          params
        )
        .then((res) => {
          return res.data;
        });
    },
  };
}

// Export a singleton instance in the global namespace
export const rentalsService = new RentalsService();
